import React from "react";
import HeadMeta from "@/components/common/HeadMeta";
import Link from "next/link";

export default function Custom404() {
  return (
    <>
      <HeadMeta title="Contenido no encontrado" />
      <section className="flex items-center min-h-screen"
        style={{
          backgroundImage: "url(/icons/actonis-icon-3.png)",
          backgroundRepeat: "repeat",
          backgroundSize: 150,
          backgroundPosition: "center top",
        }}
      >
        <div
          className="absolute w-full h-full -z-0 "
          style={{
            background:
              "linear-gradient(0deg, rgb(255, 255, 255) 50%, rgba(9,9,9,0) 100%)",
          }}
        />
        <span
          data-wow-duration="3s"
          className="w-96 h-96 rounded-full absolute top-0 left-0 -translate-x-[45%] -translate-y-20 z-10  hidden md:block bg-yellow-dark animate__animated animate__fadeIn wow"
        />
        <span
          data-wow-duration="3s"
          data-wow-delay="0.3s"
          className="w-96 h-96 rounded-full  absolute top-0 left-0 -translate-x-[35%] -translate-y-32 z-10 hidden md:block  border-4 border-gray-lighter animate__animated animate__fadeIn wow"
        />
        <main className="flex flex-col justify-center flex-auto w-full px-6 mx-auto my-auto max-w-7xl lg:px-8">
          <div className="relative z-20 text-center">
            <p className="text-base font-semibold leading-8 text-blue-light ">
              404
            </p>
            <h1 className="relative z-10 mt-5 md:mt-0 text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold uppercase wow animate__animated animate__fadeIn">
              Contenido no encontrado
            </h1>
            <p className="mt-3 text-sm font-bold leading-7 text-gray-600">
              Lo sentimos no pudimos encontrar la página que estas buscando
            </p>
            <div className="mt-3">
              <Link
                href="/"
                className="font-bold leading-7 text-blue-light"
              >
                <span aria-hidden="true" className="mr-5">&larr;</span> Regresar a Inicio
              </Link>
            </div>
          </div>
        </main>
      </section>
    </>
  );
}
